<template>
  <mp-box ref="voiceWidget" position="relative">
    <!-- // DEFAULT WIDGET -->
    <mp-flex
      v-if="!isMiniSize"
      id="drag-element"
      ref="widget"
      direction="column"
      justify="center"
      align-items="center"
      :width="widthDefaultWidget"
      height="auto"
      z-index="9999"
      background="dark"
      position="fixed"
      bottom="6"
      right="6"
      rounded="md"
      shadow="lg"
    >
      <mp-box id="drag-handle" mt="2" mb="1">
        <mp-icon
          name="drag"
          color="gray.600"
          transform="rotate(90deg)"
          cursor="move"
        />
      </mp-box>
      <mp-flex justify="space-between" px="4" width="full">
        <mp-text
          :color="onGenerateConnectionStatusColor(connectionStatus)"
          font-weight="semibold"
          font-size="lg"
        >
          {{ onGenerateConnectionStatusName(connectionStatus) }}
        </mp-text>
        <mp-flex
          v-if="
            connectionStatus === 'CALLING' ||
            connectionStatus === 'RINGING' ||
            connectionStatus === 'ON_CALL'
          "
          gap="4"
        >
          <mp-icon
            name="minus"
            color="white"
            cursor="pointer"
            variant="fill"
            @click.native="onClickButtonMinimize"
          />
          <!-- <mp-icon
            name="full-screen"
            color="white"
            cursor="pointer"
            @click.native="onClickButtonFullScreen"
          /> -->
        </mp-flex>
        <mp-icon
          v-else
          name="close"
          color="white"
          cursor="pointer"
          @click.native="onClickButtonCloseWidget"
        />
      </mp-flex>

      <mp-flex direction="column" gap="4" width="full" p="4">
        <mp-flex gap="1" align-items="center">
          <mp-icon
            :name="icon.name"
            :size="icon.size"
            :color="icon.color"
            :transform="icon.transform"
          />
          <mp-text color="white" font-size="sm">{{
            callDuration.value
          }}</mp-text>
        </mp-flex>
        <mp-flex
          position="relative"
          direction="column"
          align-items="center"
          gap="3"
          bg="gray.600"
          rounded="md"
          p="4"
        >
          <mp-box position="absolute" top="3" right="3">
            <VoiceWaveIcon
              v-if="isShowVoiceWaveIcon"
              ref="voiceWaveIcon"
              :is-start="isShowWaveAnimation"
            />
          </mp-box>
          <mp-avatar
            :name="data.user.fullname"
            :src="data.user.avatar"
            size="lg"
            z-index="1"
          />
          <mp-box
            id="pulseCircle"
            ref="pulseCircle"
            width="50px"
            height="50px"
            rounded="full"
            top="15px"
            background="white"
            position="absolute"
          >
          </mp-box>
          <mp-flex direction="column" width="full">
            <mp-text
              color="white"
              font-size="sm"
              font-weight="semibold"
              text-align="center"
              >{{ data.user.fullname }}</mp-text
            >
            <mp-text color="gray.100" font-size="sm" text-align="center">
              {{ data.user.phone }}
            </mp-text>
          </mp-flex>
        </mp-flex>

        <!-- <mp-box
          v-if="localConnectionCode"
          p="4"
          backgound="gray.50"
          border-radius="10px"
          overflow="auto"
        >
          <mp-text size="sm" color="white" mb="2" text-align="center">
            {{
              !data.connectionCodeOffer
                ? 'Share this code to let others join:'
                : 'Share this answer code with the host:'
            }}
          </mp-text>
          <mp-input v-model="localConnectionCode"> </mp-input>
        </mp-box>
        <mp-box
          v-if="!data.connectionCodeOffer"
          p="4"
          backgound="gray.50"
          border-radius="10px"
          overflow="auto"
        >
          <mp-text size="sm" color="white" mb="2" text-align="center">
            Paste answer code to connect a call:
          </mp-text>
          <mp-input v-model="inputCode"> </mp-input>
          <mp-button
            mt="2"
            width="full"
            :is-disabled="!inputCode"
            @click="onCompleteConnection"
          >
            Complete connection
          </mp-button>
        </mp-box> -->

        <mp-flex v-if="isShowButtonMicAndEndCall" justify="center" gap="4">
          <VoiceButtonDropdown
            :variant="microphone.isMuted ? 'mute' : 'unmute'"
            :label="microphone.isMuted ? 'Unmute' : 'Mute'"
            :is-speaking="isShowMicAnimation"
            @click="onClickButtonMuteUnmute"
          >
            <mp-flex direction="column" width="full">
              <mp-box px="3" py="1">
                <mp-text color="gray.400" text-align="left" font-size="sm">
                  MICROPHONE
                </mp-text>
              </mp-box>
              <mp-box v-if="isMicrophoneDetected" px="3" py="1.5">
                <mp-text color="gray.100">
                  {{
                    device.microphone.selected
                      ? device.microphone.selected.title
                      : 'No device selected'
                  }}
                </mp-text>
                <mp-text font-size="sm" color="gray.400">
                  {{
                    device.microphone.selected
                      ? device.microphone.selected.description
                      : 'Unknown'
                  }}
                </mp-text>
              </mp-box>
              <mp-flex v-else gap="2" px="3" py="1.5">
                <mp-icon
                  name="warning-triangle"
                  variant="fill"
                  color="orange.400"
                />
                <mp-text color="gray.100">No microphone detected</mp-text>
              </mp-flex>
            </mp-flex>
            <mp-divider />
            <mp-flex direction="column" width="full">
              <mp-box px="3" py="1">
                <mp-text color="gray.400" text-align="left" font-size="sm">
                  SPEAKER
                </mp-text>
              </mp-box>
              <mp-box v-if="isSpeakerDetected" px="3" py="1.5">
                <mp-text color="gray.100">
                  {{
                    device.speaker.selected
                      ? device.speaker.selected.title
                      : 'No device selected'
                  }}
                </mp-text>
                <mp-text font-size="sm" color="gray.400">
                  {{
                    device.speaker.selected
                      ? device.speaker.selected.description
                      : 'Unknown'
                  }}
                </mp-text>
              </mp-box>
              <mp-flex v-else gap="2" px="3" py="1.5">
                <mp-icon
                  name="warning-triangle"
                  variant="fill"
                  color="orange.400"
                />
                <mp-text color="gray.100">No speaker detected</mp-text>
              </mp-flex>
            </mp-flex>
          </VoiceButtonDropdown>
          <VoiceButton
            variant="decline"
            label="End"
            @click="onClickButtonEndCall"
          />
        </mp-flex>
        <mp-button
          v-if="isShowButtonCallAgain"
          justify="center"
          align-items="center"
          gap="2"
          width="full"
          height="38px"
          px="3"
          rounded="md"
          background="green.400"
          transition="box-shadow 250ms ease"
          :_hover="{
            background: 'green.500',
          }"
          :_focus="{
            outline: 'none',
            background: 'green.400',
            border: '1px solid var(--colors-green-50)',
            boxShadow: '0px 0px 0px 2px var(--colors-green-400)',
          }"
          :_active="{
            background: 'green.700',
          }"
          @click="onClickButtonCallAgain"
        >
          <mp-icon name="phone" variant="fill" size="sm" color="white" />
          <mp-text font-weight="semibold" color="white">Call again</mp-text>
        </mp-button>
        <mp-flex v-if="connectionStatus === 'CALL_ENDED'" justify="center">
          <mp-button variant="link" @click="onClickButtonCloseWidget">
            <mp-text width="full" font-size="md" color="white">
              Close ({{ closeTimer.seconds }}s)
            </mp-text>
          </mp-button>
        </mp-flex>
      </mp-flex>
    </mp-flex>

    <!-- // MINI WIDGET -->
    <mp-flex
      v-if="isMiniSize"
      id="drag-element"
      ref="widget"
      direction="row"
      justify="flex-start"
      align-items="center"
      gap="3"
      :width="widthMiniWidget"
      height="auto"
      z-index="9999"
      background="dark"
      position="fixed"
      bottom="6"
      right="6"
      rounded="100px"
      shadow="lg"
      py="2"
      px="3"
      @mouseover.native="isShowActionButton = true"
      @mouseleave.native="isShowActionButton = false"
    >
      <mp-box id="drag-handle">
        <mp-icon
          name="drag"
          color="gray.600"
          transform="rotate(0deg)"
          cursor="move"
        />
      </mp-box>
      <mp-flex
        v-if="!isShowActionButton"
        justify="space-between"
        align-items="center"
        width="full"
      >
        <mp-flex direction="column">
          <mp-flex gap="1" align-items="center">
            <mp-text
              :color="onGenerateConnectionStatusColor(connectionStatus)"
              font-weight="semibold"
              font-size="lg"
            >
              {{ onGenerateConnectionStatusName(connectionStatus) }}
            </mp-text>
            <mp-badge variant="solid" variant-color="green" size="sm">
              {{ callDuration.value }}
            </mp-badge>
          </mp-flex>

          <mp-text color="gray.100" font-size="sm">{{
            data.user.fullname
          }}</mp-text>
        </mp-flex>
        <VoiceWaveIcon
          v-if="isShowVoiceWaveIcon"
          :is-start="isShowWaveAnimation"
        />
      </mp-flex>
      <mp-flex
        v-if="isShowActionButton"
        justify="center"
        gap="4"
        width="calc(100% - 48px)"
      >
        <VoiceButton
          :variant="microphone.isMuted ? 'mute' : 'unmute'"
          @click="onClickButtonMuteUnmute"
        />
        <VoiceButton icon="picture-in-picture" @click="onClickButtonMinimize" />
      </mp-flex>
    </mp-flex>
  </mp-box>
</template>

<script>
import anime from 'animejs'
import { Draggable } from '@neodrag/vanilla'
import { mapState } from 'vuex'
import VoiceButton from './VoiceButton'
import VoiceButtonDropdown from './VoiceButtonDropdown'
import VoiceWaveIcon from './VoiceWaveIcon'
import { CALLS_URL } from '~/assets/variables/endpoints'

export default {
  name: 'CallWidget',
  components: {
    VoiceButton,
    VoiceButtonDropdown,
    VoiceWaveIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        status: '',
        user: {
          avatar: '',
          fullname: '',
          phone: '',
        },
      }),
    },
  },

  data() {
    return {
      // widget style
      pulseAnimation: '',
      drag: null,
      dragEl: null,
      containerEl: null,
      handleEl: null,
      widthDefaultWidget: '360px',
      widthMiniWidget: '220px',
      audioCalling: null,
      audioCallEnded: null,

      // call info
      icon: {
        name: 'phone',
        color: 'gray.400',
        size: 'sm',
        transform: 'rotate(0deg)',
      },
      callDuration: {
        timer: '',
        value: '00:00',
        seconds: 0,
      },
      closeTimer: {
        timer: '',
        seconds: 15,
      },
      callingTimer: {
        timer: '',
        seconds: 15,
      },
      device: {
        isCanSelectDevice: false,
        microphone: {
          selected: null,
          value: null,
          list: [],
        },
        speaker: {
          selected: null,
          value: null,
          list: [],
        },
      },
      microphone: {
        isMuted: false,
        isShowDeviceInfo: true,
      },

      // call toggle
      isVideoActive: false,
      isMicrophoneDetected: false,
      isSpeakerDetected: false,
      isCalling: false,
      isMute: false,
      isMiniSize: false,
      isShowActionButton: false,
      isShowVoiceWaveIcon: true,
      // isShowPulseAnimation: false,
      isShowWaveAnimation: false,
      isShowMicAnimation: false,

      localStream: null,
      peerConnection: null,
      iceCandidates: [],
      localConnectionCode: null,
      inputCode: '',
      connectionStatus: '', // 'CALLING' | 'ON_CALL' | 'UNANSWERED' | 'REJECTED' | 'CALL_ENDED'
    }
  },
  computed: {
    ...mapState('call', ['callWidget']),
    isShowButtonMicAndEndCall() {
      return (
        this.connectionStatus === 'CALLING' ||
        this.connectionStatus === 'RINGING' ||
        this.connectionStatus === 'ON_CALL'
      )
    },
    isShowButtonCallAgain() {
      return (
        this.connectionStatus === 'UNANSWERED' ||
        this.connectionStatus === 'REJECTED'
      )
    },
  },
  watch: {
    connectionStatus: {
      handler(newVal) {
        this.onHandleAudioEffect(newVal)
      },
    },
    callWidget: {
      handler(newVal, oldVal) {
        /**
         * NOTE:
         * Handle when REJECTED is coming first than COMPLETED.
         * Because there is no guarantee that COMPLETED will come first than REJECTED (from META).
         */
        if (oldVal.status === 'REJECTED') return

        const isAccepted = newVal.status === 'ACCEPTED'
        const isUnanswered = newVal.status === 'COMPLETED' && !newVal.duration
        const isCallEnded = newVal.status === 'COMPLETED' && newVal.duration
        const isDeclined = newVal.status === 'REJECTED'

        if (isAccepted) {
          this.onChangeConnectionStatus('ON_CALL')
          this.onStartCallDuration()
        }
        if (isUnanswered) {
          this.onChangeConnectionStatus('UNANSWERED')
        }
        if (isCallEnded) {
          this.onChangeConnectionStatus('CALL_ENDED')
        }
        if (isDeclined) {
          this.onChangeConnectionStatus('REJECTED')
        }
        if (newVal.session && newVal.session.sdp) {
          // eslint-disable-next-line no-console
          console.log('[callWidget][watch] newVal', newVal)
          this.onHandleSdpAnswer(newVal.session)
        }
      },
    },
  },
  mounted() {
    this.onStartAnimateWidget()
    this.onDragWidget()
    this.onStartCall()
  },
  beforeDestroy() {
    this.onStopCallDuration()
    this.onStopCloseTimer()
    this.onStopCallingTimer()
  },
  methods: {
    async onStartCall() {
      try {
        const userMedia = await this.onSetupUserMedia()
        if (!userMedia) {
          throw new Error('User media is not available')
        }
        this.onCreateAudioElement()
        this.onCreatePeerConnection()
        this.onSetupTrack()
        this.onCreateAndSendSdpOffer()

        // !this.data.connectionCodeOffer
        //   ? this.onCreateSdpOffer()
        //   : this.onCreateSdpAnswer()
      } catch (error) {
        this.onClickButtonCloseWidget()
      }
    },
    onHandleAudioEffect(connectionStatus) {
      try {
        switch (connectionStatus) {
          case 'CALLING':
            if (this.audioCallEnded) {
              this.audioCallEnded.pause()
              this.audioCallEnded.currentTime = 0
            }
            this.audioCalling && this.audioCalling.play()
            break
          case 'ON_CALL':
            if (this.audioCallEnded) {
              this.audioCallEnded.pause()
              this.audioCallEnded.currentTime = 0
            }
            if (this.audioCalling) {
              this.audioCalling.pause()
              this.audioCalling.currentTime = 0
            }
            break
          case 'UNANSWERED':
            if (this.audioCalling) {
              this.audioCalling.pause()
              this.audioCalling.currentTime = 0
            }
            this.audioCallEnded && this.audioCallEnded.play()
            break
          case 'REJECTED':
            if (this.audioCalling) {
              this.audioCalling.pause()
              this.audioCalling.currentTime = 0
            }
            this.audioCallEnded && this.audioCallEnded.play()
            break
          case 'CALL_ENDED':
            if (this.audioCalling) {
              this.audioCalling.pause()
              this.audioCalling.currentTime = 0
            }
            this.audioCallEnded && this.audioCallEnded.play()
            break
          default:
            if (this.audioCalling) {
              this.audioCalling.pause()
              this.audioCalling.currentTime = 0
            }
            if (this.audioCallEnded) {
              this.audioCallEnded.pause()
              this.audioCallEnded.currentTime = 0
            }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onHandleAudioEffect:', error)
      }
    },
    onGenerateConnectionStatusColor(connectionStatus) {
      let result = ''
      switch (connectionStatus) {
        case 'CALLING':
          result = 'white'
          break
        case 'RINGING':
          result = 'white'
          break
        case 'ON_CALL':
          result = 'green.400'
          break
        case 'UNANSWERED':
          result = 'rose.100'
          break
        case 'REJECTED':
          result = 'rose.100'
          break
        case 'CALL_ENDED':
          result = 'rose.100'
          break
        default:
          result = 'white'
      }
      return result
    },
    onGenerateConnectionStatusName(connectionStatus) {
      let result = ''
      switch (connectionStatus) {
        case 'CALLING':
          result = 'Calling...'
          break
        case 'RINGING':
          result = 'Ringing'
          break
        case 'ON_CALL':
          result = 'On call'
          break
        case 'UNANSWERED':
          result = 'Unanswered'
          break
        case 'REJECTED':
          result = 'Declined'
          break
        case 'CALL_ENDED':
          result = 'Call ended'
          break
        default:
          result = 'Calling...'
      }
      return result
    },
    onCreateAudioElement() {
      try {
        this.audioCalling = new Audio('/phone-calling.mp3')
        this.audioCalling.loop = true // Memutar terus-menerus

        this.audioCallEnded = new Audio('/phone-call-ended.mp3')
        this.audioCallEnded.loop = false // Memutar terus-menerus
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onCreateAudioElement:', error)
      }
    },
    // onDecryptConnectionCode(connectionCode) {
    //   try {
    //     const connectionCodeAnswer = JSON.parse(atob(connectionCode))
    //     return connectionCodeAnswer
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('onDecryptConnectionCode:', error)
    //   }
    // },
    // async onCompleteConnection() {
    //   try {
    //     if (!this.peerConnection) return
    //     const connectionCodeAnswer = this.onDecryptConnectionCode(
    //       this.inputCode
    //     )
    //     if (!connectionCodeAnswer.sdp || !connectionCodeAnswer.type) {
    //       throw new Error('Invalid answer format')
    //     }
    //     await this.peerConnection.setRemoteDescription({
    //       sdp: connectionCodeAnswer.sdp,
    //       type: connectionCodeAnswer.type,
    //     })
    //     for (const candidate of connectionCodeAnswer.candidates) {
    //       await this.peerConnection.addIceCandidate(
    //         new RTCIceCandidate(candidate)
    //       )
    //     }
    //     this.onStartCallDuration()
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('onCompleteConnection:', error)
    //   }
    // },
    onCreatePeerConnection() {
      try {
        const config = {
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
        }
        const pc = new RTCPeerConnection(config)
        if (!pc) return
        this.peerConnection = pc
        this.onSetupPeerConnectionListeners(pc)
        return pc
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onCreatePeerConnection:', error)
      }
    },
    async onSetupUserMedia() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: this.isVideoActive,
        })
        if (!stream) return
        this.localStream = stream
        this.onSetupAnimationByVoice(stream)
        return stream
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onSetupUserMedia:', error)
        const isMicrophoneNotAllowed = error.message === 'Permission denied'

        // Error toast
        this.$toast({
          variant: 'error',
          title: isMicrophoneNotAllowed
            ? 'Please allow microphone access permission'
            : error.message,
          position: 'top',
          duration: 3000,
        })

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [onSetupUserMedia] Failed to setup user media',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error: error?.message ?? error,
          }
        )
      }
    },
    onSetupAnimationByVoice(stream) {
      try {
        // running pulse animation
        this.onInitPulseAnimation()

        // Create an audio context
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)()
        const analyser = audioContext.createAnalyser()
        const source = audioContext.createMediaStreamSource(stream)

        // Connect source to analyser
        source.connect(analyser)

        // Set analyser properties
        analyser.fftSize = 32 // Small FFT for low latency
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        // Update the animation based on audio data
        const updateWave = () => {
          analyser.getByteFrequencyData(dataArray)

          // // Normalize and update levels (scale: 0-100%)
          // const level = Array.from(dataArray.slice(0, 5)).map((value) =>
          //   Math.min((value / 255) * 100, 100)
          // )

          // Calculate average volume
          const volume =
            dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length

          // Threshold for triggering the pulse effect
          const threshold = 50 // Adjust this value as needed

          if (volume > threshold) {
            this.onStartAnimation('pulseAnimation', true)
            this.onStartAnimation('waveAnimation', true)
            // this.onStartAnimation('micAnimation', true)
          } else {
            this.onStartAnimation('waveAnimation', false)
            this.onStartAnimation('pulseAnimation', false)
            // this.onStartAnimation('micAnimation', false)
          }

          requestAnimationFrame(updateWave) // Loop the animation
        }

        updateWave()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onSetupAnimationByVoice:', error)
      }
    },
    onSetupTrack() {
      try {
        if (this.localStream) {
          this.localStream.getTracks().forEach((track) => {
            this.peerConnection.addTrack(track, this.localStream)

            // push track into device (microphone or speaker) list
            this.device.microphone.list.push({
              id: track?.id ?? '',
              title: track?.label?.split(' - ')?.[0] ?? 'Unknown',
              description: track?.label?.split(' - ')?.[1] ?? 'Unknown',
              value: track?.id ?? '',
            })
            this.device.speaker.list.push({
              id: track?.id ?? '',
              title: track?.label?.split(' - ')?.[0] ?? 'Unknown',
              description: track?.label?.split(' - ')?.[1] ?? 'Unknown',
              value: track?.id ?? '',
            })
          })

          this.device.microphone.list = this.device.microphone.list.map(
            (item) => ({
              ...item,
              title: `System ${item.title}`,
            })
          )
          this.device.speaker.list = this.device.speaker.list.map((item) => ({
            ...item,
            title: `System ${item.title}`,
          }))

          // Auto set default microphone & speaker
          this.isMicrophoneDetected = this.device.microphone.list.length > 0
          this.isSpeakerDetected = this.device.speaker.list.length > 0
          this.onSelectDevice('microphone', this.device.microphone.list[0])
          this.onSelectDevice('speaker', this.device.speaker.list[0])
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onSetupTrack:', error)

        // Error toast
        this.$toast({
          variant: 'error',
          title: error.message,
          position: 'top',
          duration: 3000,
        })

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [onSetupTrack] Failed to setup track and microphone',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error: error?.message ?? error,
          }
        )
      }
    },
    onSelectDevice(type, device) {
      this.device[type].value = device.value
      this.device[type].selected = device
    },
    onSetupPeerConnectionListeners(pc) {
      try {
        this.onIceCandidate(pc)
        this.onConnectionStateChange(pc)
        // this.onIceConnectionStateChange(pc)
        this.onTrack(pc)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onSetupPeerConnectionListeners - error:', error)
      }
    },
    onIceCandidate(pc) {
      try {
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            this.iceCandidates.push(event.candidate)
            // this.onGenerateLocalConnectionCode()
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onIceCandidate:', error)
      }
    },
    onConnectionStateChange(pc) {
      try {
        pc.onconnectionstatechange = () => {
          switch (pc.connectionState) {
            case 'connected':
              break
            case 'disconnected':
              this.onChangeConnectionStatus('CALL_ENDED')
              break
            case 'failed':
              this.onChangeConnectionStatus('UNANSWERED')

              break
            case 'closed':
              this.onChangeConnectionStatus('CALL_ENDED')
              break
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onConnectionStateChange:', error)
      }
    },
    onIceConnectionStateChange(pc) {
      try {
        pc.oniceconnectionstatechange = () => {
          if (pc.iceConnectionState === 'disconnected') {
            this.onChangeConnectionStatus('CALL_ENDED')
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onIceConnectionStateChange:', error)
      }
    },
    onTrack(pc) {
      try {
        pc.ontrack = (event) => {
          // if (this.isVideoActive && this.remoteVideoRef) {
          //   this.remoteVideoRef.srcObject = event.streams[0];
          //   return;
          // }

          const remoteStream = new MediaStream()
          event.streams[0].getTracks().forEach((track) => {
            remoteStream.addTrack(track)
          })
          const audioElement = new Audio()
          audioElement.srcObject = remoteStream
          audioElement.play()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onTrack:', error)
      }
    },
    // onGenerateLocalConnectionCode() {
    //   try {
    //     const data = {
    //       sdp: this.peerConnection?.localDescription?.sdp,
    //       type: this.peerConnection?.localDescription?.type,
    //       candidates: this.iceCandidates,
    //     }
    //     this.localConnectionCode = btoa(JSON.stringify(data))
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('onGenerateLocalConnectionCode - error', error)
    //   }
    // },
    async onCreateAndSendSdpOffer() {
      try {
        // if (!this.data.connectionCodeOffer) this.connectionStatus = 'CALLING'
        this.onChangeConnectionStatus('CALLING')
        const offerOptions = {
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 0,
        }
        const offerCode = await this.peerConnection.createOffer(offerOptions)
        await this.peerConnection.setLocalDescription(offerCode)
        const payload = {
          room_id: this.$route.query.room || '',
          type: 'calls',
          sdp: this.peerConnection?.localDescription.sdp,
        }
        const additional = {
          headers: {
            Authorization: this.$auth.getToken('hub'),
          },
        }
        if (!payload.room_id) throw new Error('Room ID is required')
        await this.$axios.post(`${CALLS_URL}/wa_cloud`, payload, additional)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onCreateSdpOffer:', error)
        this.onChangeConnectionStatus('CALL_ENDED')

        // Error toast
        this.$toast({
          variant: 'error',
          title: error.response?.data?.error?.messages?.[0] ?? error.message,
          position: 'top',
          duration: 3000,
        })

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [onCreateAndSendSdpOffer] Failed create and send SDP Offer',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error:
              error.response?.data?.error?.messages?.[0] ??
              error?.message ??
              error,
          }
        )
      }
    },
    async onHandleSdpAnswer(session) {
      try {
        if (!this.peerConnection) return
        if (this.peerConnection.signalingState === 'stable') return
        await this.peerConnection.setRemoteDescription({
          sdp: session.sdp,
          type: session.sdp_type,
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onHandleSdpAnswer:', error)

        // Error toast
        this.$toast({
          variant: 'error',
          title: error.response?.data?.error?.messages?.[0] ?? error.message,
          position: 'top',
          duration: 3000,
        })

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [onHandleSdpAnswer] Failed to handle SDP Answer',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error: error?.message ?? error,
          }
        )
      }
    },
    // async onCreateSdpOffer() {
    //   try {
    //     if (!this.data.connectionCodeOffer) this.connectionStatus = 'CALLING'
    //     const offerCode = await this.peerConnection.createOffer()
    //     await this.peerConnection.setLocalDescription(offerCode)
    //     this.onGenerateLocalConnectionCode()
    //     // const payload = {
    //     //   room_id: this.$route.query.room || '',
    //     //   type: 'calls',
    //     //   sdp: this.peerConnection?.localDescription.sdp,
    //     // }
    //     // const additional = {
    //     //   headers: {
    //     //     Authorization: this.$auth.getToken('hub'),
    //     //   },
    //     // }
    //     // if (!payload.room_id) throw new Error('Room ID is required')
    //     // await this.$axios.post(`${CALLS_URL}/wa_cloud`, payload, additional)
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('onCreateSdpOffer:', error)
    //     onChangeConnectionStatus('UNANSWERED')
    //     // this.onClickButtonEndCall()
    //     this.$toast({
    //       variant: 'error',
    //       title: error.response?.data?.error?.messages?.[0] ?? error.message,
    //       position: 'top',
    //       duration: 3000,
    //     })
    //     this.storeMixpanelMetric('Inbox - [API] Failed to send SDP Offer', {
    //       Role: this.$auth.user.role,
    //       Email: this.$auth.user.email,
    //       'Company ID': this.getOrganizationForMetric()['Company ID'],
    //       'Company Name': this.getOrganizationForMetric()['Company Name'],
    //       'Room ID': this.$route.query?.room ?? '',
    //       Error: error.response?.data?.error?.messages?.[0] ?? error.message,
    //     })
    //   }
    // },
    // async onCreateSdpAnswer() {
    //   try {
    //     const code = this.onDecryptConnectionCode(this.data.connectionCodeOffer)
    //     if (!code.sdp || !code.type) {
    //       throw new Error('Invalid answer format')
    //     }
    //     await this.peerConnection.setRemoteDescription({
    //       sdp: code.sdp,
    //       type: code.type,
    //     })
    //     for (const candidate of code.candidates) {
    //       await this.peerConnection.addIceCandidate(
    //         new RTCIceCandidate(candidate)
    //       )
    //     }
    //     const answerCode = await this.peerConnection.createAnswer()
    //     await this.peerConnection.setLocalDescription(answerCode)
    //     this.onGenerateLocalConnectionCode()
    //     this.onStartCallDuration()
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('onCreateSdpAnswer:', error)
    //     this.$toast({
    //       variant: 'error',
    //       title: error.response?.data?.error?.messages?.[0] ?? error.message,
    //       position: 'top',
    //       duration: 3000,
    //     })
    //     this.storeMixpanelMetric('Inbox - [API] Failed to send SDP Answer', {
    //       Role: this.$auth.user.role,
    //       Email: this.$auth.user.email,
    //       'Company ID': this.getOrganizationForMetric()['Company ID'],
    //       'Company Name': this.getOrganizationForMetric()['Company Name'],
    //       'Room ID': this.$route.query?.room ?? '',
    //       Error: error.response?.data?.error?.messages?.[0] ?? error.message,
    //     })
    //   }
    // },
    onClickButtonEndCall() {
      try {
        if (!this.callWidget.id) return
        this.onChangeConnectionStatus('CALL_ENDED')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onClickButtonEndCall:', error)

        // Store metric to mixpanel
        this.storeMixpanelMetric('Inbox - [WebRTC] Failed to end call', {
          Role: this.$auth.user.role,
          Email: this.$auth.user.email,
          'Company ID': this.getOrganizationForMetric()['Company ID'],
          'Company Name': this.getOrganizationForMetric()['Company Name'],
          'Room ID': this.$route.query?.room ?? '',
          Error: error?.message ?? error,
        })
      }
    },
    onResetCallStream() {
      this.onResetLocalStream()
      this.onResetPeerConnection()
      this.onResetRemoteStream()
    },
    async onPostTerminateEndpoint() {
      try {
        const additional = {
          headers: {
            Authorization: this.$auth.getToken('hub'),
          },
        }
        const payload = {
          room_id: this.$route.query?.room ?? '',
          calls_id: this.callWidget?.id ?? '',
          type: 'calls',
        }
        await this.$axios.post(
          `${CALLS_URL}/wa_cloud/terminate`,
          payload,
          additional
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onPostTerminateEndpoint:', error)

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [API] Failed to post into terminate call endpoint',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error:
              error.response?.data?.error?.messages?.[0] ??
              error?.message ??
              error,
          }
        )
      }
    },
    onResetPeerConnection() {
      try {
        if (this.peerConnection) {
          this.peerConnection.close()
          this.peerConnection = null
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onResetPeerConnection:', error)
      }
    },
    onResetLocalStream() {
      try {
        if (this.localStream) {
          this.localStream.getTracks().forEach((track) => {
            track.stop()
            track.enabled = false
          })
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onResetLocalStream - error:', error)
      }
    },
    onResetRemoteStream() {
      try {
        // remove src on audio local stream and remote stream
        const remoteAudioElement = document.querySelector('audio')
        if (remoteAudioElement && remoteAudioElement.srcObject) {
          const remoteStream = remoteAudioElement.srcObject
          remoteStream.getTracks().forEach((track) => track.stop())
          remoteAudioElement.srcObject = null
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onResetRemoteStream:', error)
      }
    },
    onClickButtonMuteUnmute() {
      try {
        if (this.connectionStatus !== 'ON_CALL') {
          // eslint-disable-next-line no-console
          console.error(
            '[onClickButtonMuteUnmute] connectionStatus is not on call'
          )
          return
        }
        this.localStream.getAudioTracks().forEach((track) => {
          track.enabled = this.microphone.isMuted
        })
        this.microphone.isMuted = !this.microphone.isMuted
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onButtonMuteClicked:', error)

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [Microphone] Failed to mute microphone',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error: error?.message ?? error,
          }
        )
      }
    },
    onClickButtonMinimize() {
      this.isMiniSize = !this.isMiniSize

      this.$nextTick(() => {
        this.onStartAnimateWidget()
      })
      this.$emit('minimize', this.isMiniSize)
    },
    onClickButtonFullScreen() {
      alert('Go to fullscreen')
      this.$emit('full-screen')
    },
    onClickButtonCallAgain() {
      this.$store.commit('call/UPDATE_CALL_WIDGET', {
        session: '',
        event: '',
        status: '',
        id: '',
      })
      this.onStopCloseTimer()
      this.onStartCall()
    },
    onStartAnimation(type, value) {
      // pulse animation
      if (type === 'pulseAnimation' && value) {
        this.onInitPulseAnimation(true)
      }
      if (type === 'pulseAnimation' && !value) {
        this.onInitPulseAnimation(false)
      }

      // wave animation
      if (type === 'waveAnimation' && value) {
        this.isShowWaveAnimation = true
      }
      if (type === 'waveAnimation' && !value) {
        this.isShowWaveAnimation = false
      }

      // mic animation
      if (type === 'micAnimation' && value) {
        this.isShowMicAnimation = true
      }
      if (type === 'micAnimation' && !value) {
        this.isShowMicAnimation = false
      }
    },
    onInitPulseAnimation(isStart) {
      if (isStart === undefined) {
        this.pulseAnimation = anime({
          targets: this.$refs.pulseCircle.$el,
          scale: [0, 1.5],
          opacity: [1, 0],
          easing: 'easeOutSine',
          duration: 1200,
          loop: false,
          autoplay: false,
        })
      }
      isStart && this.pulseAnimation.play()
    },
    onStartAnimateWidget() {
      anime({
        targets: this.$refs.widget.$el,
        opacity: [0, 1],
        translateY: this.isMiniSize ? [-80, 0] : [160, 0],
        width: this.isMiniSize
          ? [this.widthDefaultWidget, this.widthMiniWidget]
          : [this.widthMiniWidget, this.widthDefaultWidget],
        easing: 'easeOutSine',
        duration: 200,
        delay: 50,
      })
    },
    onDragWidget() {
      this.dragEl = document.querySelector('#drag-element')
      this.containerEl = document.querySelector('.mp-main-body')
      this.handleEl = document.querySelector('#drag-handle')

      if (this.dragEl && this.containerEl && this.handleEl) {
        this.drag = new Draggable(this.dragEl, {
          defaultPosition: { x: 0, y: 0 },
          axis: 'both',
          handle: this.handleEl,
          bounds: this.containerEl,
        })
      }
    },
    onStartCallDuration() {
      this.callDuration.timer = setInterval(() => {
        this.callDuration.seconds += 1 // Increment seconds
        this.callDuration.value = this.onFormatTime(this.callDuration.seconds)
      }, 1000)
    },
    onStopCallDuration() {
      this.onStartAnimation('pulseAnimation', false)
      this.onStartAnimation('waveAnimation', false)
      clearInterval(this.callDuration.timer)
      this.callDuration.timer = null
    },
    onStartCloseTimer() {
      this.closeTimer.seconds = 15
      this.closeTimer.timer = setInterval(() => {
        this.closeTimer.seconds -= 1 // Decrement seconds
        if (this.closeTimer.seconds < 0) {
          this.onClickButtonCloseWidget()
        }
      }, 1000)
    },
    onStopCloseTimer() {
      clearInterval(this.closeTimer.timer)
      this.closeTimer.timer = null
    },
    onStartCallingTimer() {
      this.callingTimer.seconds = 15
      this.callingTimer.timer = setInterval(() => {
        this.callingTimer.seconds -= 1 // Decrement seconds
        if (this.connectionStatus === 'ON_CALL') {
          this.onStopCallingTimer()
        }
        if (
          this.callingTimer.seconds < 0 ||
          this.connectionStatus === 'UNANSWERED'
        ) {
          this.onChangeConnectionStatus('UNANSWERED')
        }
      }, 1000)
    },
    onStopCallingTimer() {
      clearInterval(this.callingTimer.timer)
      this.callingTimer.timer = null
    },
    onFormatTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      const secs = seconds % 60
      return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(
        2,
        '0'
      )}`
    },
    onClickButtonCloseWidget() {
      this.onStopCloseTimer()
      this.$store.commit('call/UPDATE_CALL_WIDGET', {
        user: {
          avatar: '',
          fullname: '',
          phone: '',
        },
        session: '',
        event: '',
        status: '',
        id: '',
      })
      this.audioCalling = null
      this.audioCallEnded = null
    },
    async onChangeConnectionStatus(status) {
      try {
        switch (status) {
          case 'CALLING':
            this.icon.transform = 'rotate(0deg)'
            this.connectionStatus = 'CALLING'
            break
          // case 'RINGING':
          //   this.connectionStatus = 'RINGING'
          //   break
          case 'ON_CALL':
            this.connectionStatus = 'ON_CALL'
            break
          case 'UNANSWERED':
            this.connectionStatus = 'UNANSWERED'
            this.icon.transform = 'rotate(135deg)'
            this.onResetCallStream()
            this.onStopCallDuration()
            this.onStopCallingTimer()
            this.onStopCloseTimer()
            this.onStartCloseTimer()
            break
          case 'REJECTED':
            this.connectionStatus = 'REJECTED'
            this.icon.transform = 'rotate(135deg)'
            this.onResetCallStream()
            this.onStopCallDuration()
            this.onStopCallingTimer()
            this.onStopCloseTimer()
            this.onStartCloseTimer()
            break
          case 'CALL_ENDED':
            this.icon.transform = 'rotate(135deg)'
            this.onStopCallingTimer()
            this.connectionStatus = 'CALL_ENDED'
            // call endpoint terminate - no matter endpoint status code
            await this.onPostTerminateEndpoint()
            this.onResetCallStream()
            this.onStopCallDuration()
            this.onStopCloseTimer()
            this.onStartCloseTimer()
            break
          default:
            this.connectionStatus = 'CALLING'
            break
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('onChangeConnectionStatus:', error)

        // Error toast
        this.$toast({
          variant: 'error',
          title: error.message,
          position: 'top',
          duration: 3000,
        })

        // Store metric to mixpanel
        this.storeMixpanelMetric(
          'Inbox - [onChangeConnectionStatus] Failed to change connection status',
          {
            Role: this.$auth.user.role,
            Email: this.$auth.user.email,
            'Company ID': this.getOrganizationForMetric()['Company ID'],
            'Company Name': this.getOrganizationForMetric()['Company Name'],
            'Room ID': this.$route.query?.room ?? '',
            Error: error?.message ?? error,
          }
        )
      }
    },
  },
}
</script>
